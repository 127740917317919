@import "./src/assets/scss/styles.scss";

.staging-bar {
  background: #3ea644;
  bottom: 0;
  color: #fff;
  display: flex;
  font-size: 7px;
  padding: 3px 25px 3px 20px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 15;

  &--small {
    width: auto;
  }
}

.staging-bar-title {
  text-align: center;
  width: 100%;
}

.staging-bar-action {
  cursor: pointer;
  position: absolute;
  right: 5px;

  img {
    height: 9px;
  }
}
