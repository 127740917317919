@import "./src/assets/scss/styles.scss";

.floating-menu-container {
  background-color: #fff;
  font-family: Inter, sans-serif;
  height: 100%;
  position: fixed;
  text-align: end;
  width: 100%;
  z-index: 1000;
}

.floating-menu-close-button {
  appearance: none;
  border: none;
  background: transparent;
  color: #6e7780;
  cursor: pointer;
  font-size: 30px;
  height: 40px;
  line-height: normal;
  padding: 0;
  position: relative;
  right: 13px;
  top: -1px;
  z-index: 1100;
}

.floating-menu-items {
  height: 100%;
  margin-top: 3px;
  position: relative;
}

:deep(.floating-menu-item) {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: flex-start;
  padding: 15px 0;
  text-decoration: none;
  width: 100%;

  &.router-link-active {
    .floating-menu-item-name {
      margin-left: 4px;
    }

    img {
      border-left: 8px solid #ff8300;
      filter: unset;
      margin-left: -4px;
    }
  }

  &:hover {
    background-color: #f7fbff;
  }

  img {
    filter: grayscale(5);
    height: 27px;
    width: var(--navbar-width);
  }

  .floating-menu-item-name {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: 300;
    flex-wrap: nowrap;
    align-items: center;
    text-align: left;
    color: #6e7780;
    text-decoration: none;
    transition: background 0.25s;
    cursor: pointer;
    height: 100%;
  }
}
