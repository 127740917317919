@import "./src/assets/scss/styles.scss";

.sidebar-container {
  background: #fff;
  border-right: 0.1rem solid #e3e9ef;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: var(--navbar-width);
}

.sidebar-list {
  height: 100%;
  margin-top: 3px;
  position: relative;
}

.sidebar-logo {
  align-items: flex-start;
  display: flex;
  justify-content: center;

  img {
    margin: 24px auto;
  }
}

:deep(.sidebar-item) {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 15px 0;
  width: 100%;

  &.router-link-active {
    .icon-wrapper {
      border-left: 8px solid #ff8300;

      margin-left: -8px;
    }
    .icon-wrapper > img {
      filter: unset;
    }
  }

  &:hover {
    background-color: #f7fbff;

    .tooltip-box {
      visibility: visible;
    }
  }

  .icon-wrapper > img {
    filter: grayscale(5);
    height: 27px;
    width: 100%;
  }
  .icon-wrapper {
    width: 100%;
  }

  .tooltip-box {
    display: flex;
    flex-direction: column;
    height: 46px;
    justify-content: center;
    left: 70px;
    pointer-events: none;
    position: absolute;
    visibility: hidden;

    .tooltip-text {
      align-items: center;
      background: #fff !important;
      border: 1px solid #8c969f;
      border-radius: 4px !important;
      color: #363b40 !important;
      display: flex;
      font-family: Inter, sans-serif !important;
      font-size: 16px;
      font-weight: 500;
      height: 46px;
      justify-content: center;
      left: -3px;
      padding: 5px;
      position: absolute;
      width: max-content;
      z-index: 100;
    }

    .tooltip-arrow {
      border: unset !important;
      color: transparent !important;
      pointer-events: none !important;
    }

    .tooltip-arrow::before {
      border: 7px solid transparent;
      border-right-color: #8c969f;
      content: '';
      display: block;
      position: absolute;
      right: 2px;
      top: 17px;
      width: 0;
    }

    .tooltip-arrow::after {
      border: 5px solid transparent;
      border-right-color: #fff;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      right: 2px;
      top: 19px;
      z-index: 101;
    }
  }
}
