@import "./src/assets/scss/styles.scss";

.header {
  align-items: center;
  border-bottom: #e3e9ef 1px solid;
  display: flex;
  justify-content: space-between;
  left: var(--navbar-width);
  padding: 20px 32px;
  position: fixed;
  width: calc(100vw - var(--navbar-width));
  background: white;
  /* needed because header is fixed, and notification center popover might overlap 
     with z-index'd elements on some pages (e.g. Assignments)  
  */
  z-index: 12;

  &-mobile {
    left: 0;
    padding: 15px 16px;
    width: 100%;
  }

  &-fleet {
    border-bottom: none;
  }

  .header-title {
    color: #363b40;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    width: fit-content;
  }
}
