@import "./src/assets/scss/styles.scss";

.top-user-menu {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.top-user-menu-icon {
  width: 32px;
  color: #8c969f;
}
.notifications-icon {
  height: 24px;
}
.top-user-menu-link {
  cursor: pointer;
  display: flex;
  margin-left: 16px;
  align-items: center;
}

.top-menu-list {
  cursor: pointer;
  margin-left: 16px;
}
