@import "./src/assets/scss/styles.scss";

.user-details {
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  .user-details-company {
    font-size: 20px;
  }
}
.user-profile-link {
  display: flex;
}
