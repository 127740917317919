@import "./src/assets/scss/styles.scss";

.language-dropdown {
  display: flex;
  flex-direction: column;

  &--hide {
    display: none;
  }
  .language-dropdown-item {
    display: flex;
    justify-content: space-between;
    .language-check-icon {
      width: 16px;
    }
  }
}

.v-popper__arrow-container {
  display: none;
}
