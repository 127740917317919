@import "./src/assets/scss/styles.scss";

.dropdown-menu-item {
  font-family: Inter, sans-serif;
  font-size: 14px;
  color: #363b40;
  display: flex;
  align-items: center;
  padding: 13px;
  cursor: pointer;
  min-width: 160px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .dropdown-menu-item-text {
    margin-left: 8px;
  }

  &:hover {
    background-color: #f7fbff;
  }
}
