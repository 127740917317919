@import "./src/assets/scss/styles.scss";

.app-container {
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-columns: var(--navbar-width) 1fr;
  grid-template-rows: 78px 1fr;
  grid-template-areas:
    'sidebar header'
    'sidebar content'
    'sidebar footer';

  &-mobile {
    grid-template-columns: auto;
  }
}
.content {
  grid-area: content;
  max-width: calc(100vw - var(--navbar-width));

  :deep(.fleet-navigation) {
    top: 76px !important;
    z-index: 1;
  }

  :deep(.navigation-tabs) {
    top: 101px !important;
  }

  :deep(.orders-navigation) {
    z-index: 1;
  }

  &--mobile {
    max-width: calc(100vw);

    :deep(.fleet-navigation) {
      top: 66px !important;
    }

    :deep(.navigation-tabs) {
      top: 91px !important;
      width: 100%;
    }
  }
  &.header-mf--hidden {
    :deep(.fleet-navigation),
    :deep(.navigation-tabs) {
      top: -20% !important;
    }
  }
}
.sidebar {
  grid-area: sidebar;
  z-index: 2;
}
.header {
  background: #fff;
  grid-area: header;
  transition: top 0.6s ease-in-out;

  &--hidden {
    top: -20%;
  }
}
.footer {
  grid-area: footer;
}

@media screen and (max-width: 768px) {
  .app-container {
    grid-template-rows: 68px 1fr;
  }
}

@media screen and (max-width: 446px) {
  .app-container {
    grid-template-rows: 98px 1fr;
  }
}
