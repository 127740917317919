@import "./src/assets/scss/styles.scss";

.app-content {
  display: flex;
  flex-direction: column;

  .footer {
    margin-top: auto;
  }
}

.mobile-menu {
  z-index: var(--dsf-z-index-layout);
}
