@import 'floating-vue/dist/style.css';
@import '@sennder/design-system-core/dist/styles/themes/default.css';

/* to make sure smart banner is always displayed, for consistent look with sticky header  */
.smartbanner {
  position: fixed !important;

  &__info {
    width: 50% !important;
  }
}
html {
  /* When rolling out re-branding, we need to calculate this */
  --navbar-width: 72px;
}