*, *::before, *::after {
  box-sizing: border-box;
}

*,
body {
  margin: 0;
}

html, body {
  height: 100%;
}

html {
  font-size: 10px;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
//TODO: uncomment it when UI library global styles will be fixed
//img, picture, video, canvas, svg {
//  display: block;
//  max-width: 100%;
//}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
