@import "./src/assets/scss/styles.scss";

.user-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  height: 32px;
  width: 32px;
  border: 1px solid #ffe3c4;
  background: #ffe3c4;
  border-radius: 32px;

  font: 400 16px 'Inter', sans-serif;
  color: #363b40;
}
